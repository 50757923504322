import { Box } from '../../primitives'
import type { CSS } from '../../stitches'
import { styled, theme } from '../../stitches'

export interface SurfaceProps extends React.ComponentProps<typeof Surface> {}

const bordered: CSS = {
  borderWidth: 1,
  borderColor: theme.colors['surface-primary-border'],
  borderStyle: 'solid',
}

export const Surface = styled(Box, {
  variants: {
    variant: {
      page: {},
      flat: {
        borderRadius: theme.radii[16],
      },
      snippet: {
        borderRadius: theme.radii[16],
        boxShadow: theme.shadows['elevation-100'],
      },
      popover: {
        borderRadius: theme.radii[8],
        boxShadow: theme.shadows['elevation-200'],
      },
      toolbar: {
        borderRadius: theme.radii[16],
        boxShadow: theme.shadows['elevation-200'],
      },
      log: {
        borderRadius: theme.radii[16],
      },
      ghost: {
        borderRadius: theme.radii[16],
        backgroundImage: theme.backgrounds['surface-ghost-border'],
      },
    },
    colorScheme: {
      primary: {
        backgroundColor: theme.colors['surface-primary-background'],
        color: theme.colors['surface-primary-text'],
      },
      secondary: {
        backgroundColor: theme.colors['surface-secondary-background'],
        color: theme.colors['surface-secondary-text'],
      },
      grey: {
        backgroundColor: theme.colors['surface-grey-background'],
        color: theme.colors['surface-grey-text'],
      },
      blue: {
        backgroundColor: theme.colors['surface-blue-background'],
        color: theme.colors['surface-blue-text'],
      },
      black: {
        backgroundColor: theme.colors['surface-black-background'],
        color: theme.colors['surface-black-text'],
      },
      yellow: {
        backgroundColor: theme.colors['surface-yellow-background'],
        color: theme.colors['surface-yellow-text'],
      },
    },
  },
  compoundVariants: [
    {
      colorScheme: 'primary',
      variant: 'flat',
      css: bordered,
    },
    {
      colorScheme: 'primary',
      variant: 'popover',
      css: bordered,
    },
    {
      colorScheme: 'primary',
      variant: 'toolbar',
      css: bordered,
    },
    {
      colorScheme: 'primary',
      variant: 'ghost',
      css: {
        color: theme.colors['surface-ghost-text'],
        backgroundColor: theme.colors['surface-ghost-background'],
      },
    },
    {
      colorScheme: 'primary',
      variant: 'log',
      css: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.colors['surface-log-border'],
      },
    },
    {
      colorScheme: 'grey',
      variant: 'flat',
      css: {
        borderRadius: theme.radii[8],
      },
    },
  ],
  defaultVariants: {
    variant: 'flat',
    colorScheme: 'primary',
  },
})

Surface.displayName = 'Surface'
