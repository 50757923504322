import 'salad/static/fonts.css'
import * as Sentry from '@sentry/react'
import { getSessionId } from 'domains/analytics/utils'
import isNetworkError from 'is-network-error'
import {
  AuthError,
  ManualCancellationError,
  ExtendedResponseError,
} from 'kitchen/utils/error'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BackofficeApp } from './bo-app'

const element = document.getElementById('root')

if (
  ['staging', 'production'].includes(process.env.BUILD_MODE) &&
  process.env.BRANCH_STAGING === undefined && // disable on PR preview stands
  process.env.PLAYWRIGHT === undefined // disable for test runs
) {
  Sentry.init({
    environment: process.env.BUILD_MODE,
    dsn: process.env.SENTRY_DSN,
    release: process.env.GIT_RELEASE,
    ignoreErrors: [/^AbortError/],
    beforeSend: (event, hint) => {
      if (
        hint.originalException instanceof ExtendedResponseError ||
        hint.originalException instanceof ManualCancellationError ||
        hint.originalException instanceof AuthError ||
        isNetworkError(hint.originalException)
      ) {
        return null
      }

      return event
    },
  })

  Sentry.setTag('sessionId', getSessionId())
}

if (element !== null) {
  const root = createRoot(element)

  root.render(
    <StrictMode>
      <BackofficeApp />
    </StrictMode>
  )
}
