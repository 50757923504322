import { ImpossibleError } from 'kitchen/utils/error'
import { Portal } from 'radix-ui'
import { Status, Spinner, Delayed, Surface, GlobalBlur } from 'salad/components'
import { StatusLayout } from 'salad/layouts'
import { theme } from 'salad/stitches'

export interface LoadingStatusProps {
  layout: 'page' | 'inline' | 'dialog' | 'overlay'
  delay?: number
}

export function LoadingStatus({ delay, layout }: LoadingStatusProps) {
  const spinner = <Spinner size={64} color={layout === 'overlay' ? 'white' : 'grey-10'} />

  const content = (
    <Status.Root>
      <Status.Icon>{spinner}</Status.Icon>
    </Status.Root>
  )

  switch (layout) {
    case 'page':
      return (
        <Delayed delay={delay}>
          <StatusLayout.Root>
            <StatusLayout.Content>{content}</StatusLayout.Content>
          </StatusLayout.Root>
        </Delayed>
      )
    case 'overlay':
      return (
        <Delayed delay={delay}>
          <GlobalBlur />
          <Portal.Root>
            <StatusLayout.Root
              css={{
                position: 'fixed',
                inset: 0,
                backgroundColor: theme.colors['black-alpha-10'],
              }}
            >
              <StatusLayout.Content>{content}</StatusLayout.Content>
            </StatusLayout.Root>
          </Portal.Root>
        </Delayed>
      )
    case 'inline':
      return <Delayed delay={delay}>{content}</Delayed>
    case 'dialog':
      return (
        <Delayed delay={delay}>
          <Surface variant="flat" p={32}>
            {content}
          </Surface>
        </Delayed>
      )
    default:
      throw new ImpossibleError('Unhandled layout', layout)
  }
}
